.infoBox {
  padding: 8px;
  margin: 0;
  width: 90%;
  max-height: 70%;
  overflow-y: scroll;
  background-color: #fff;
}

.infoBox .textContainer {
  display: flex;
  column-gap: 10px;
}

.infoBox .textContainer .value {
  margin-top: 20px;
}

.infoBox .textContainer .field {
  margin-bottom: 0;
  font-size: 18px;
}
