.about {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about h2 {
  font-size: 40px;
  color: rgb(10, 14, 228);
  text-decoration: underline;
  text-decoration-color: rgb(10, 14, 228);
  margin-top: 0;
}

.paragraphContainer {
  max-width: 80%;
  background-color: white;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 28px;
  border-radius: 20px;
  color: rgb(0, 0, 68);
}

.paragraphContainer h3 {
  color: rgb(10, 14, 228);
}

.paragraphContainer h4 {
  color: rgb(10, 14, 228);
}
