/* ||Console */
.console {
  display: flex;
  flex-direction: column;
 /*  padding-top: 100px;
  padding-bottom: 50px; */
  margin-top: 90px;
}
.classRoomsContainer {
  padding: 0;
  margin: 0;
  padding-bottom: 6px;
  padding-left: 20px;
  margin-bottom: 8px;
  background-color: #fff;
  box-shadow: 2px 2px 3px #333;
  list-style-type: none;
  width: 80%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  position: relative;
}

.consoleHeader {
  background-color: white;
  height: 66px;
  width: 100%;
  display: flex;
  z-index: 2;

  position: fixed;
  top: 0;

  box-shadow: 2px 0px 3px #333;
}

.consoleHeader h1 {
  justify-self: center;
  align-self: center;
}

.consoleLogo {
  width: 80px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.classRooms {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.classRoom {
  display: flex;
  background-color: #fff;
  width: 90%;
  margin-bottom: 6px;
  margin-top: 6px;
  padding: 5px;
  border-bottom: #333 double 4px;
}

.classRoom:hover {
  cursor: pointer;
  font-weight: 600;
}

.classRoomName {
  margin-right: 25%;
}

.createClassRoomBtnContainer {
  position: sticky;
  bottom: 3px;
  width: 90%;
}

.sendLetterBtn {
  width: fit-content;
  border: none;
  border-radius: 5px;
  background-color: rgb(3, 128, 128);

  font-size: 20px;
  font-weight: 700;
  color: #fff;

  padding: 5px;
  margin-left: 65%;
  margin-bottom: 10px;
}

.sendLetterBtn:hover {
  cursor: pointer;
  background-color: aqua;
}

.deleteButton:hover {
  color: aqua;
}

@media (max-width: 780px) {
  .consoleLogo {
    left: 80%;
  }
}
