.formsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 65px;
}

.formsContainer h1 {
  color: rgb(10, 14, 228);
  font-size: 38px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
}

.form {
  background-color: #fff;
  padding: 30px;
  margin-bottom: 6px;

  border: aquamarine solid 2px;
  border-radius: 12px;
}

/* .form h1{
  color: rgb(10, 14, 228);
  font-size: 39px;
  font-weight: 800;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-align: center;
} */
.form h2{
  color: rgb(10, 14, 228);
  font-size: 25px;
  font-weight: 800;
  font-family:'Times New Roman', Times, serif;
  text-align: center;
}

.largeForm {
  width: 85%;
}

.form div {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  margin-bottom: 10px;
}

.form div input {
  border-color: aquamarine;
  border-radius: 5px;
}

.form div label {
  color: rgb(0, 2, 105);
  font-weight: 700;
}

.mediumFormInput {
  min-height: 28px;
}

.form div textarea {
  border-color: aquamarine;
  border-radius: 5px;
  border-width: 2px;

  min-height: 80px;
  max-width: 99%;
}

.formHeader {
  background-color: white;
  height: 66px;
  width: 100%;
  display: flex;
  z-index: 2;

  position: fixed;
  top: 0;
  box-shadow: 2px 0px 3px #333;
}
