.letterSenderFormContainer {
  padding: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.letter {
  margin: 10px;
  width: 48%;
  background-color: #fff;
  overflow-wrap: break-word;
  display: flex;
  flex-direction: column;
}

.letterBody {
  height: 80%;
  margin: 8px;
}

.letterParagraph {
  margin-bottom: 10px;
}
.letterClosing {
  margin: 0;
}

.letterSignature {
  font-family: "cursive, sans-serif, Roboto";
  font-weight: 100;
}

@media (max-width: 750px) {
  .letterSenderFormContainer {
    flex-direction: column;
    align-items: center;
  }
  .letter {
    min-width: 80%;
  }
}
