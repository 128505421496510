.page {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.pageDivider {
  width: 100%;
  height: 50px;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageDivider div {
  width: 100px;
  height: 5px;
  background-color: grey;
  border-radius: 40%;


}

.button {
  width: fit-content;
  margin-top: 6px;

  border: none;
  border-radius: 5px;
  background-color: rgb(3, 128, 128);

  font-size: 20px;
  font-weight: 700;
  color: #fff;

  padding: 5px;
}
.button:hover {
  cursor: pointer;
  background-color: aqua;
}

.button2 {
  background-color: rgb(3, 128, 128);
  padding: 2px;
  font-weight: 700;
  font-size: 14;
  color: white;
  border: none;
  border-radius: 5px;

  margin: 10px;
}
.button2:hover {
  cursor: pointer;
  background-color: aqua;
}

.textLink {
  color: rgb(3, 128, 128);
}

.textLink:hover {
  color: aqua;
  cursor: pointer;
}

.button3 {
  margin-top: 6px;
  margin-right: 30px;

  color: rgb(3, 128, 128);
  font-size: 20px;
  font-weight: 600;
  padding: 2px;
  border: none;
  border-radius: 5px;
}
.button3:hover {
  cursor: pointer;
  color: aqua;
}
.backButton {
  margin-left: 20px;
}
