.frontPage {
  background-color: white;
  padding: 0;
  padding-top: 90px;

  margin: 0;
  min-height: 100%;
}

.header {
  background-color: white;
  min-height: 66px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 2;

  position: fixed;
  top: 0;

  box-shadow: 2px 0px 3px #333;
}

.announcement {
  background-color: white;
  width: 100%;
  min-height: 30px;
  z-index: 2;
  display: flex;
  gap: 5px;
  position: fixed;
  top: 68px;
  box-shadow: 2px 0px 3px #333;
  padding-left: 30px;
}

.navMenu {
  margin: 0;
  margin-right: 10px;
  margin-top: 8px;
  list-style-type: none;

  display: flex;
  column-gap: 10px;
}

.navMenuItem {
  background-color: aqua;
  padding: 8px;
  border-radius: 4px;
}

.menuButton {
  display: none;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translateX(-50%);
}

.dropDown {
  transition: all ease-in 0.5s;
}
.dropDownItem:hover {
  cursor: pointer;
}
.sideImageContainer {
  position: absolute;
  top: 10px;
  left: 0;
}

.hero {
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  gap: 10px;
}

.heroHeadingContainer {
  width: 49vw;
  background-image: radial-gradient(aliceblue, white);
}

.heroHeading {
  font-size: 70px;
  color: rgb(10, 14, 228);
  margin: 0;
  margin-left: 9px;
}

.heroParagraph {
  font-size: 25px;
  margin-left: 9px;
  font-weight: 600;
  font-family: "Courier New", Courier, monospace;
  color: rgb(0, 0, 68);
}

.heroImageContainer {
  width: 50vw;
}

.heroImage {
  width: 49vw;
  margin-top: 50px;
}

.heroCenterImage {
  margin-top: 50px;
  position: absolute;
  right: 30px;

  max-width: 50vw;
  max-height: 80vh;
}

.about {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about h2 {
  font-size: 40px;
  color: rgb(10, 14, 228);
  text-decoration: underline;
  text-decoration-color: rgb(10, 14, 228);
}

.freeWebsite {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.freeWebsite h2 {
  font-size: 40px;
  color: rgb(10, 14, 228);
  text-decoration: underline;
  text-decoration-color: rgb(10, 14, 228);
}

.frontPageParagraphContainer {
  max-width: 80%;
  background-color: aliceblue;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 28px;
  border-radius: 20px;
  color: rgb(0, 0, 68);
  flex-direction: row;
}

.frontPageParagraphContainer h3 {
  color: rgb(10, 14, 228);
}

@media (max-width: 780px) {
  .heroHeading {
    font-size: 60px;
  }
}

@media (max-width: 710px) {
  .frontPage {
    padding-top: 130px;
  }
  .navContainer {
    display: none;
  }
  .menuButton {
    display: block;
  }
  .hero {
    flex-direction: column;
    align-items: center;
  }
  .heroHeadingContainer {
    width: 90vw;
  }
  .heroImageContainer {
    width: 100%;
    display: flex;
  }
  .heroCenterImage {
    position: relative;
    transform-origin: center;
    transform: translateX(50%);
  }
  
  .frontPageParagraphContainer {
      flex-direction: column;
  }
}

@media (max-width: 430px) {
  .frontPage {
    padding-top: 160px;
  }
  .heroHeading {
    font-size: 40px;
  }
}
